<template>
  <v-app>
    <v-main>
      <v-container class="main">
        <v-row justify="center">
          <v-col cols="12" md="11" lg="10">
            <h1 class="text-center">Términos y Condiciones</h1>
            <br>
            <h2>Declaración del Servicio:</h2>
            <p>Yo, en calidad de usuario y alumno matriculado, declaro que USAMEDIC S.C.R.L. identificada con R.U.C. N° 20515316711 es la titular y proveedora de los servicios de formación académica en Medicina y Ciencias de la Salud presentes, así como de los cursos virtuales y/o presenciales que obran en la presente página web.</p>
            <h2>Política de asistencia a clases:</h2>
            <p>En calidad de usuario matriculado, acepto las condiciones de asistencia a clases virtuales y/o presenciales en el presente curso seleccionado, siendo mi obligación asistir a todas y cada una de las sesiones cronogramadas; sin embargo, declaro que USAMEDIC brinda la posibilidad de acumular un 30% de faltas contabilizadas conforme a sus sistemas de asistencia. También se evaluarán los casos de inasistencias por caso fortuito o fuerza mayor, dependiendo de cada caso en concreto. Por su parte, en caso exista reprogramación de las clases, estas serán notificadas al alumno con la anticipación debida.</p>
            <h2>Políticas de pago:</h2>
            <p>Acepto adherirme a las presentes condiciones de pago estipuladas en la presente cláusula. Los pagos del curso USAMEDIC, ya sean totales por adelantado o en las cuotas indicadas, serán realizados a través de los mecanismos de pago electrónico disponibles en la página web de USAMEDIC utilizando tarjetas de débito o crédito; sin perjuicio a ello, el alumno podrá realizar el pago en físico en la oficina principal de USAMEDIC, conforme a su domicilio legal.</p>
            <p>En los casos que el alumno haya optado por el pago fraccionado del curso seleccionado, este se obliga a cancelar las cuotas en las fechas señaladas. En casos de incumplimiento de pago por parte del alumno, USAMEDIC se reserva el derecho de restringir el acceso al campus virtual y a las clases virtuales y/o presenciales hasta que el alumno cumpla con realizar el pago de la cuota devengada.</p>
            <p>En los casos de promociones de cursos USAMEDIC en la modalidad de preventa, el alumno deberá cancelar el pago en la oficina principal de USAMEDIC para poder acceder a los descuentos ofrecidos. Los pagos podrán realizarse mediante efectivo, trasferencia bancaria a las cuentas proporcionadas por USAMEDIC conforme a las fechas de inscripción y el uso de VISANET mediante tarjetas de débito y crédito.</p>
            <p>Declaro y acepto que, bajo ningún supuesto, USAMEDIC deberá realizar devolución alguna de los pagos realizados, bajo ningún concepto y en ninguna circunstancia.</p>
            <h2>Entrega de Materiales:</h2>
            <p>USAMEDIC otorgará una serie de materiales bibliográfico y académico conforme a cada curso seleccionado por el alumno. La entrega del material físico estará sujeto a las siguientes condiciones: </p>
            <ul>
              <li>
                a) En caso el alumno resida en la ciudad de Lima, deberá recoger el material en la oficina principal de USAMEDIC en un plazo no mayor a 5 meses contabilizado desde el inicio del curso seleccionado.
              </li>
              <li style="margin-left: 19px;">
                El recojo será verificado con el DNI o Pasaporte del alumno inscrito. Transcurrido el mes de entrega, y sin que el alumno haya recogido el material, este se dará como entregado sin lugar a reclamos.
              </li>
              <li>
                b) En caso el alumno resida en provincia, contará con 5 meses de plazo para comunicar vía correo electrónico a USAMEDIC y poder gestionar el envío del material a la ciudad de destino indicada por el
              </li>
              <li style="margin-left: 19px;">
                alumno. Las entregas se realizarán mediante encomienda a través de Olva Courier, para a cuyo efecto, el alumno deberá recoger el material en la agencia de Olva Courier ubicada en la ciudad de destino. En caso transcurra el mes de plazo sin gestión alguna de envío, se aplicará lo dispuesto en el inciso precedente.
              </li>
            </ul>
            <br>
            <h2>Propiedad Intelectual y protección de derechos:</h2>
            <p>Declaro y acepto que los materiales físicos y/o virtuales, así como los cursos, mecanismos, metodologías, procedimientos, know how, textos, compendios médicos o académicos y todo lo proporcionado en el presente curso es de titularidad exclusiva de USAMEDIC, conforme a la normativa de propiedad intelectual. Para cuyo efecto, USAMEDIC autoriza al alumno utilizar todo lo proporcionado en el presente curso, siempre y cuando el alumno haga referencia directa a la titularidad del mismo y dicho uso no se ejecute en agravio de USAMEDIC, bajo sanción de que esta última pueda iniciar las acciones legales pertinentes para salvaguardar su derecho.</p>
            <h2>Políticas de uso:</h2>
            <p>USAMEDIC autoriza al alumno la utilización normal, no exclusiva y de buena fe de los cursos y productos adquiridos mediante la presente matrícula, siempre y cuando se encuentre en armonía con las políticas y protección de los derechos intelectuales de titularidad de USAMEDIC y los derechos de propiedad intelectual de terceros.</p>
            <p>USAMEDIC brindará acceso al campus virtual hasta la finalización del curso elegido, de acuerdo a su cronograma. El acceso al campus virtual será de uso exclusivo del alumno matriculado en el curso de USAMEDIC; para tal efecto, el alumno solo podrá acceder al curso seleccionado en un máximo de 1 dispositivos por cuenta, con el fin de evitar suspensiones de cuentas por uso indebido o difusión de la cuenta, bajo sanción de suspender esta última definitivamente y sin lugar a reclamo. Las cuentas, claves y tokens son personales para cada alumno USAMEDIC y tienen carácter intransferible.</p>
            <p>Cada curso USAMEDIC contará con una gestión de comunicación entre los funcionarios USAMEDIC, coordinadores, docentes y alumnos mediante grupos de WhatsApp. En tal sentido, el alumno podrá utilizar dichos grupos para consultas académicas, compartir contenido de interés para el grupo y demás integrantes, así como requerimientos específicos los cuales se dirigirán a los encargados y administradores. Empero, el alumno se encuentra prohibido de utilizar los grupos de WhatsApp para enviar contenido de carácter personal, cadenas, memes y toda clase de contenido que no tenga relación con el curso USAMEDIC seleccionado. Además, en dichos grupos de WhatsApp se encuentra prohibida toda clase de discriminación en agravio de algún integrante del grupo, sea cual sea su posición de alumno, coordinador, docente u otro. Finalmente, el alumno se encuentra prohibido de utilizar los grupos de WhatsApp para mencionar cualquier clase de simulacros, pruebas, contenido o mención referente a otras academias diferentes a USAMEDIC.</p>
            <h2>Autorización de uso de datos personales:</h2>
            <p>Para efectos de poder inscribirme en el presente curso seleccionado, autorizo a USAMEDIC el uso de mis datos personales y datos complementarios requeridos, los cuáles serán tratados conforme a lo regulado en la Ley N° 29733 Ley de datos personales y su reglamento; además, autorizo a USAMEDIC para que dichos datos personales y complementarios puedan ser transferidos a terceros sin limitación alguna, conforme a los mecanismos y procedimientos estipulados en dichas normas. Por su parte, autorizo a USAMEDIC el uso de mi imagen y nombre para fines estrictamente publicitarios.</p>
            <h2>Ley aplicable y jurisdicción:</h2>
            <p>Declaro y acepto la aplicación de las normas peruanas a cualquier tipo de controversia derivada del presente contrato, asimismo, acepto someterme a la jurisdicción de los tribunales de la ciudad de Lima.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'TermsAndConditions'
}
</script>

<style lang="scss" scoped>
.main {
  & p {
    text-align: justify;
  }
  & li {
    list-style-type:none;
  }
  & h2 {
    margin-bottom: 10px;
  }
}
</style>
